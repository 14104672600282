<template>
  <div :class="['app--wrapper default', isWordBreak]">
    <NavigationDesktop :navbarColor="navbarColor" />
    <NavigationMobile :navbarColor="navbarColor" />
    <NavigationFloatingPromo v-if="!isFloatingPromoHidden" />
    <main id="main-content" tabindex="-1">
      <slot />
    </main>
    <div id="notification-outlet" />
    <LazyFooterCustom :footerColor="footerColor" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  navbarColor?: string
  footerColor?: string
}
defineProps<Props>()
const route = useRoute()

const floatingPromoExclusionRegex = new RegExp(
  [
    '^/trusted-ai$',
    '^/newsroom(/.*)?$',
    '^(/de)?/industries/public-health-and-government$',
    '^(/de)?/case-studies/.+',
    '^(/de)?/product/symptom-checker$',
    '^/careers(/.*)?$',
  ].join('|'),
)

const isFloatingPromoHidden = computed(() => {
  return floatingPromoExclusionRegex.test(route.path)
})

const { locale } = useI18n()
const isWordBreak = computed(() => (locale.value === 'de' ? 'break-word' : ''))
useSetScreenValues()
</script>
